import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const get_products = createAsyncThunk(
    'product/get_products',
    async ({perPage,page,searchValue,productId},{rejectWithValue,fulfillWithValue}) => {
       
        try {
            const {data} = await api.get(`/get-products?page=${page}&&searchValue=${searchValue}&&perPage=${perPage}${productId !=='' ? `&&productId=${productId}` : ''}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)
export const get_products_search = createAsyncThunk(
    'product/get_products_search',
    async ({
        min,
        max,
        category,
        rating,
        search,
        brand,
        sortPrice,
        pageNumber,
        cards
    }, { rejectWithValue, fulfillWithValue }) => {
        // Create base API endpoint with required query params
        let createApi = `/get-products-search?category=${encodeURIComponent(category)}&&rating=${rating}&&min=${min}&&max=${max}&&sortPrice=${sortPrice}&&search=${encodeURIComponent(search)}&&brand=${brand}&&pageNumber=${pageNumber}&&cards=${cards}`;

        try {
            // Make API request
            const { data } = await api.get(createApi, { withCredentials: true });
            console.log(data);
            return fulfillWithValue(data);  // Fulfill the thunk with data on success
        } catch (error) {
            console.log(error);
            // Check if error response exists and return the error, or return a generic error
            const errorMessage = error.response?.data || 'Something went wrong';
            return rejectWithValue(errorMessage);  // Reject the thunk with error data
        }
    }
);


export const get_product = createAsyncThunk(
    'product/get_product',
    async (productId,{rejectWithValue,fulfillWithValue}) => {
       console.log(productId);
       
        try {
            const {data} = await api.get(`/get-product/${productId}`, {withCredentials: true})
            console.log(data);
            return fulfillWithValue(data);
        } catch (error){
            console.log(error.response.data);
            return rejectWithValue(error.response.data);
        }
    }
)


export const productReducer = createSlice({
    name: 'product',
    initialState: {
        successMessage: '',
        errorMessage: '',
        loader: false,
        products: [],
        products_search: [],
        product:'',
        totalProducts: 5
    },
    reducers: {
        messageClear: (state, _) => {
            state.errorMessage = ""
            state.successMessage = ""
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(get_products.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_products.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_products.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.products = payload.products
            state.totalProducts = payload.totalProducts
        })

        .addCase(get_products_search.pending, (state, { payload }) => {
            state.loader = true 
        }).addCase(get_products_search.rejected, (state, { payload }) => {
            state.loader = false;
            state.errorMessage = payload.error 
        }).addCase(get_products_search.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.products_search = payload.products
            state.totalProducts = payload.totalProducts
        })


        .addCase(get_product.fulfilled, (state, { payload }) => {
            state.loader = false;
            state.product = payload.product
        })

    }
})

export default productReducer.reducer

export const {messageClear} = productReducer.actions